
import React from "react";
import { Link } from 'react-router-dom';
class InnerPageHeader extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {  
        var {pageTitle,breadcrumb,breadcrumbPath,action} = this.props;
        return (
            <div className="row wrapper border-bottom white-bg page-heading">
                <div className="col-lg-10">
                    <h2>{pageTitle}</h2>
                    <ol className="breadcrumb">
                        <li>
                            <Link to="/">Home</Link>
                        </li>
                        <li>
                            <Link to={breadcrumbPath}>{breadcrumb}</Link>
                        </li>
                        <li className="active">
                            <strong>{action}</strong>
                        </li>
                    </ol>
                </div>
                <div className="col-lg-2">

                </div>
            </div>
        )
    }
}

export default InnerPageHeader