import React, { Component } from "react";
import { Link } from "react-router-dom";
import InnerPageHeader from "./InnerPageHeader";
import { connect } from "react-redux";
import SimpleReactValidator from "simple-react-validator";
import { toast, ToastContainer } from "react-toastify";
import { Redirect } from "react-router-dom";

class AddUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formObj: {
        username: "",
        first_name: "",
        last_name: "",
        my_share: 0.0,
        my_agent_share: 0.0,
        game_share: 0.0,
        agent_game_share: 0.0,
        matka_share: 0.0,
        matka_agent_share: 0.0,
        fixed_limit: 0.0,
        match_commission: 0.0,
        session_commission: 0.0,
        mobile_app_share: 0.0,
        casions: "",
        // disable_single_digit:"",
        // single_digit_commission: "",
        // disable_inside_outside:"",
        // disable_roulette:"",
        // disable_triple_chance:"",
        // disable_king_queen_jack:"",
        password: "",
        confirm_password: "",
        role: "",
      },
      profileData: "",
    };

    this.validator = new SimpleReactValidator();
  }

  componentDidMount() {
    this.userNameRule();
    let profile = JSON.parse(localStorage.getItem("profile"));
    this.setState({ profileData: profile });
    this.setState((state) => ({
      formObj: {
        ...state.formObj,
        role: this.props.role,
      },
    }));
    if (this.props.parentType && this.props.parentType === "subcompany") {
      this.setState((state) => ({
        formObj: {
          ...state.formObj,
          mobile_app_share: 100,
        },
      }));
    }
  }

  setMyShareSubCompanyValue = (val = 0) => {
    if (this.props.parentType && this.props.parentType === "subcompany") {
      this.setState((state) => ({
        formObj: {
          ...state.formObj,
          my_share: 100 - val,
        },
      }));
    }
  };
  userNameRule = () => {
    let { parentType } = this.props;

    if (parentType) {
      var prefix = "";
      switch (parentType) {
        case "subcompany":
          prefix = "SC";
          break;
        case "master":
          prefix = "BSM";
          break;
        case "superstockist":
          prefix = "BSA";
          break;
        case "stockist":
          prefix = "BSB";
          break;
        case "agent":
          prefix = "BSC";
          break;
        default:
          prefix = "";
      }
      let number = Math.floor(Math.random() * 90000) + 10000;
      let predefinedUsername = `${prefix}${number}`;
      this.setState((state) => ({
        formObj: {
          ...state.formObj,
          username: predefinedUsername,
        },
      }));
    }
  };
  onSubmit = (e) => {
    e.preventDefault();
    let { formObj } = this.state;
    if (this.validator.allValid()) {
      this.props.onSubmitFunc(formObj);
      // this.props.dispatch(
      //   createRequest(formObj, (res) => {
      //     if (res) this.setState({ redirect: "master" });
      //   })
      // );
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  handleInput = (e) => {
    const { name, value } = e.target; // <-- moved outside asynchronous context

    this.setState((state) => ({
      formObj: {
        ...state.formObj,
        [name]: value,
      },
    }));
  };
  render() {
    let userRole = localStorage.getItem("role") || null;
    let { redirect, profileData } = this.state;
    const {
      heading,
      breadcrumbTitle,
      breadcrumb,
      navigationUrl,
      action,
      parentType,
    } = this.props;
    if (redirect) {
      return <Redirect to={redirect} />;
    }

    console.log(profileData, "profileData");
    return (
      <>
        <InnerPageHeader
          pageTitle={breadcrumbTitle}
          breadcrumb={breadcrumb}
          breadcrumbPath={navigationUrl}
          action={action}
        />
        <div className="wrapper wrapper-content animated fadeInRight">
          <div className="row">
            <div className="col-lg-12">
              <div className="ibox float-e-margins">
                <div className="ibox-title">
                  <h5>{heading ? heading : "Create"}</h5>
                </div>
                <div className="ibox-content">
                  <form onSubmit={this.onSubmit} className="form-horizontal">
                    <div className="form-group">
                      <label className="col-sm-3 control-label">
                        User name
                      </label>
                      <div className="col-sm-9">
                        <input
                          type="text"
                          className="form-control"
                          name="username"
                          id="username"
                          value={this.state.formObj.username}
                          onChange={(e) => this.handleInput(e)}
                          disabled
                        />
                        <em>
                          {this.validator.message(
                            "username",
                            this.state.formObj.username,
                            "required"
                          )}
                        </em>
                      </div>
                    </div>
                    <div className="hr-line-dashed"></div>
                    <div className="form-group">
                      <label className="col-sm-3 control-label">
                        First name
                      </label>
                      <div className="col-sm-9">
                        <input
                          type="text"
                          className="form-control"
                          name="first_name"
                          id="first_name"
                          value={this.state.formObj.first_name}
                          onChange={(e) => this.handleInput(e)}
                        />
                        <em>
                          {this.validator.message(
                            "first_name",
                            this.state.formObj.first_name,
                            "required"
                          )}
                        </em>
                      </div>
                    </div>
                    <div className="hr-line-dashed"></div>
                    <div className="form-group">
                      <label className="col-sm-3 control-label">
                        Last name
                      </label>
                      <div className="col-sm-9">
                        <input
                          type="text"
                          className="form-control"
                          name="last_name"
                          id="last_name"
                          value={this.state.formObj.last_name}
                          onChange={(e) => this.handleInput(e)}
                        />
                        <em>
                          {this.validator.message(
                            "last_name",
                            this.state.formObj.last_name,
                            "required"
                          )}
                        </em>
                      </div>
                    </div>
                    <div className="hr-line-dashed"></div>
                    {parentType === "subcompany" ? (
                      <>
                        <div className="form-group">
                          <label className="col-sm-3 control-label">
                            Company Match Earning Share
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="number"
                              className="form-control"
                              name="my_agent_share"
                              id="my_agent_share_subcompany"
                              value={this.state.formObj.my_agent_share}
                              onChange={(e) => {
                                this.handleInput(e);
                                this.setMyShareSubCompanyValue(e.target.value);
                              }}
                              max={999}
                            />
                            <em>
                              {this.validator.message(
                                "my_agent_share",
                                this.state.formObj.my_agent_share,
                                "required"
                              )}
                            </em>
                          </div>
                        </div>
                        <div className="hr-line-dashed"></div>
                        <div className="form-group">
                          <label className="col-sm-3 control-label">
                            Company Usage Charges Share
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="number"
                              className="form-control"
                              name="mobile_app_share"
                              id="mobile_app_share_subcompany"
                              value={this.state.formObj.mobile_app_share}
                              onChange={(e) => this.handleInput(e)}
                              disabled
                            />
                            <em>
                              {this.validator.message(
                                "mobile_app_share",
                                this.state.formObj.mobile_app_share,
                                "required"
                              )}
                            </em>
                          </div>
                        </div>
                        <div className="hr-line-dashed"></div>
                      </>
                    ) : null}

                    {parentType !== "subcompany" ? (
                      <>
                        <div className="form-group">
                          <label className="col-sm-3 control-label">
                            My Share Sports (cricket, tennis , soccer){" "}
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="number"
                              className="form-control"
                              name="my_share"
                              id="my_share"
                              max={
                                userRole && (userRole == "1" || userRole == "2")
                                  ? 100
                                  : this.state.formObj.my_agent_share
                                  ? profileData &&
                                    profileData.my_agent_share &&
                                    profileData.my_agent_share -
                                      this.state.formObj.my_agent_share
                                  : profileData && profileData.my_agent_share
                              }
                              value={this.state.formObj.my_share}
                              onChange={(e) => this.handleInput(e)}
                            />
                            <div className="agent_share_error">
                              <b>Note</b> : Max Agent Share + My Share should be
                              set below or equal to{" "}
                              <b>
                                {userRole &&
                                (userRole == "1" || userRole == "2")
                                  ? 100
                                  : profileData && profileData.my_agent_share}
                              </b>
                            </div>
                            <em>
                              {this.validator.message(
                                "my_share",
                                this.state.formObj.my_share,
                                "required"
                              )}
                            </em>
                          </div>
                        </div>
                        <div className="hr-line-dashed"></div>
                        <div className="form-group">
                          <label className="col-sm-3 control-label">
                            Max Agent Share Sports (cricket, tennis , soccer)
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="number"
                              className="form-control"
                              name="my_agent_share"
                              id="my_agent_share"
                              max={
                                userRole && (userRole == "1" || userRole == "2")
                                  ? 100
                                  : this.state.formObj.my_share
                                  ? profileData &&
                                    profileData.my_share &&
                                    profileData.my_agent_share -
                                      this.state.formObj.my_share
                                  : profileData && profileData.my_agent_share
                              }
                              value={this.state.formObj.my_agent_share}
                              onChange={(e) => this.handleInput(e)}
                            />
                            <div className="agent_share_error">
                              <b>Note</b> : Max Agent Share + My Share should be
                              set below or equal to{" "}
                              <b>
                                {userRole &&
                                (userRole == "1" || userRole == "2")
                                  ? 100
                                  : profileData && profileData.my_agent_share}
                              </b>
                            </div>
                            <em>
                              {this.validator.message(
                                "my_agent_share",
                                this.state.formObj.my_agent_share,
                                "required"
                              )}
                            </em>
                          </div>
                        </div>
                        {/* <div className="hr-line-dashed"></div>
                    <div className="form-group">
                      <label className="col-sm-3 control-label">My Share</label>
                      <div className="col-sm-9">
                        <input
                          type="text"
                          className="form-control"
                          name="my_share"
                          id="my_share"
                        />
                        <div className="agent_share_error"><b>Note</b> : Max Agent Share + My Share should be set below or equal to <b>100.0</b></div>
                        <em>
                          {this.validator.message(
                            "my_share",
                            this.state.formObj.my_share,
                            "required"
                          )}
                        </em>
                      </div>
                    </div> */}
                        <div className="hr-line-dashed"></div>
                        <div className="form-group">
                          <label className="col-sm-3 control-label">
                            My Share (casino, horse racing and other)
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="number"
                              className="form-control"
                              name="game_share"
                              id="game_share"
                              max={
                                userRole && (userRole == "1" || userRole == "2")
                                  ? 100
                                  : this.state.formObj.agent_game_share
                                  ? profileData &&
                                    profileData.agent_game_share &&
                                    profileData.agent_game_share -
                                      this.state.formObj.agent_game_share
                                  : profileData && profileData.agent_game_share
                              }
                              value={this.state.formObj.game_share}
                              onChange={(e) => this.handleInput(e)}
                            />
                            <div className="agent_share_error">
                              <b>Note</b> : Max Agent Share + My Share should be
                              set below or equal to{" "}
                              <b>
                                {userRole &&
                                (userRole == "1" || userRole == "2")
                                  ? 100
                                  : profileData && profileData.agent_game_share}
                              </b>
                            </div>
                            <em>
                              {this.validator.message(
                                "game_share",
                                this.state.formObj.game_share,
                                "required"
                              )}
                            </em>
                          </div>
                        </div>
                        <div className="hr-line-dashed"></div>
                        <div className="form-group">
                          <label className="col-sm-3 control-label">
                            Max Agent Share (casino, horse racing and other)
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="number"
                              className="form-control"
                              name="agent_game_share"
                              id="agent_game_share"
                              max={
                                userRole && (userRole == "1" || userRole == "2")
                                  ? 100
                                  : this.state.formObj.game_share
                                  ? profileData &&
                                    profileData.agent_game_share &&
                                    profileData.agent_game_share -
                                      this.state.formObj.game_share
                                  : profileData && profileData.agent_game_share
                              }
                              value={this.state.formObj.agent_game_share}
                              onChange={(e) => this.handleInput(e)}
                            />
                            <div className="agent_share_error">
                              <b>Note</b> : Max Agent Share + My Share should be
                              set below or equal to{" "}
                              <b>
                                {userRole &&
                                (userRole == "1" || userRole == "2")
                                  ? 100
                                  : profileData && profileData.agent_game_share}
                              </b>
                            </div>
                            <em>
                              {this.validator.message(
                                "agent_game_share",
                                this.state.formObj.agent_game_share,
                                "required"
                              )}
                            </em>
                          </div>
                        </div>
                        <div className="hr-line-dashed"></div>
                        <div className="form-group">
                          <label className="col-sm-3 control-label">
                            My Share (matka)
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="number"
                              className="form-control"
                              name="matka_share"
                              id="matka_share"
                              value={this.state.formObj.matka_share}
                              onChange={(e) => this.handleInput(e)}
                              max={
                                userRole && (userRole == "1" || userRole == "2")
                                  ? 100
                                  : this.state.formObj.matka_agent_share
                                  ? profileData &&
                                    profileData.matka_agent_share &&
                                    profileData.matka_agent_share -
                                      this.state.formObj.matka_agent_share
                                  : profileData && profileData.matka_agent_share
                              }
                            />
                            <div className="agent_share_error">
                              <b>Note</b> : Max Agent Share + My Share should be
                              set below or equal to{" "}
                              <b>
                                {userRole &&
                                (userRole == "1" || userRole == "2")
                                  ? 100
                                  : profileData &&
                                    profileData.matka_agent_share}
                              </b>
                            </div>
                            <em>
                              {this.validator.message(
                                "matka_share",
                                this.state.formObj.matka_share,
                                "required"
                              )}
                            </em>
                          </div>
                        </div>
                        <div className="hr-line-dashed"></div>
                        <div className="form-group">
                          <label className="col-sm-3 control-label">
                            Max Agent Share (matka)
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="number"
                              className="form-control"
                              name="matka_agent_share"
                              id="matka_agent_share"
                              max={
                                userRole && (userRole == "1" || userRole == "2")
                                  ? 100
                                  : this.state.formObj.matka_share
                                  ? profileData &&
                                    profileData.matka_agent_share &&
                                    profileData.matka_agent_share -
                                      this.state.formObj.matka_share
                                  : profileData && profileData.matka_agent_share
                              }
                              value={this.state.formObj.matka_agent_share}
                              onChange={(e) => this.handleInput(e)}
                            />
                            <div className="agent_share_error">
                              <b>Note</b> : Max Agent Share + My Share should be
                              set below or equal to{" "}
                              <b>
                                {userRole &&
                                (userRole == "1" || userRole == "2")
                                  ? 100
                                  : profileData &&
                                    profileData.matka_agent_share}
                              </b>
                            </div>
                            <em>
                              {this.validator.message(
                                "matka_agent_share",
                                this.state.formObj.matka_agent_share,
                                "required"
                              )}
                            </em>
                          </div>
                        </div>
                        <div className="hr-line-dashed"></div>
                      </>
                    ) : null}
                    <div className="form-group">
                      <label className="col-sm-3 control-label">
                        Agent Fix Limit
                      </label>
                      <div className="col-sm-9">
                        <input
                          type="number"
                          className="form-control"
                          name="fixed_limit"
                          id="fixed_limit"
                          value={this.state.formObj.fixed_limit}
                          onChange={(e) => this.handleInput(e)}
                          max={profileData && profileData.balance}
                        />
                        <div className="agent_share_error">
                          <b>Note</b> :Agent Fix Limit set below or equal to{" "}
                          <b>{profileData && profileData.balance}</b>
                        </div>
                        <em>
                          {this.validator.message(
                            "fixed_limit",
                            this.state.formObj.fixed_limit,
                            "required"
                          )}
                        </em>
                      </div>
                    </div>
                    <div className="hr-line-dashed"></div>
                    <div className="form-group">
                      <label className="col-sm-3 control-label">
                        Agent Match Commission
                      </label>
                      <div className="col-sm-9">
                        <input
                          type="number"
                          className="form-control"
                          name="match_commission"
                          id="match_commission"
                          value={this.state.formObj.match_commission}
                          onChange={(e) => this.handleInput(e)}
                          max={
                            userRole && userRole == "1"
                              ? 100
                              : profileData && profileData.match_commission
                          }
                        />
                        <div className="agent_share_error">
                          <b>Note</b> : Agent Match Commission set below or
                          equal to{" "}
                          <b>
                            {userRole && userRole == "1"
                              ? 100
                              : profileData && profileData.match_commission}
                          </b>
                        </div>
                        <em>
                          {this.validator.message(
                            "match_commission",
                            this.state.formObj.match_commission,
                            "required"
                          )}
                        </em>
                      </div>
                    </div>
                    <div className="hr-line-dashed"></div>
                    <div className="form-group">
                      <label className="col-sm-3 control-label">
                        Agent Session Commission
                      </label>
                      <div className="col-sm-9">
                        <input
                          type="number"
                          className="form-control"
                          name="session_commission"
                          id="session_commission"
                          value={this.state.formObj.session_commission}
                          onChange={(e) => this.handleInput(e)}
                          max={
                            userRole && userRole == "1"
                              ? 100
                              : profileData && profileData.session_commission
                          }
                        />
                        <div className="agent_share_error">
                          <b>Note</b> : Agent Session Commission set below or
                          equal to{" "}
                          <b>
                            {userRole && userRole == "1"
                              ? 100
                              : profileData && profileData.session_commission}
                          </b>
                        </div>
                        <em>
                          {this.validator.message(
                            "session_commission",
                            this.state.formObj.session_commission,
                            "required"
                          )}
                        </em>
                      </div>
                    </div>
                    <div className="hr-line-dashed"></div>
                    {/* <div className="form-group">
                      <label className="col-sm-3 control-label">Casinos</label>
                      <div className="col-sm-9">
                        <input
                          type="text"
                          className="form-control"
                          name="casions"
                          id="casions"
                        />
                        <em>
                          {this.validator.message(
                            "casions",
                            this.state.formObj.casions,
                            "casions"
                          )}
                        </em>
                      </div>
                    </div>
                    <div className="hr-line-dashed"></div> */}
                    {/* <Form.Group className="form-group" controlId="formBasicCheckbox">
                                            <Form.Label className="col-sm-3 control-label">Disable Single Digit</Form.Label>
                                            <Form.Check type="checkbox" label="" name="disable_single_digit" id="disable_single_digit" />
                                            <em>{this.validator.message('disable_single_digit', this.state.formObj.disable_single_digit, 'required')}</em>
                                        </Form.Group> */}
                    {/* <div className="hr-line-dashed"></div> */}
                    {/* <div className="form-group">
                      <label className="col-sm-3 control-label">
                        Single Digit Commission
                      </label>
                      <div className="col-sm-9">
                        <input
                          type="text"
                          className="form-control"
                          name="single_digit_commission"
                          id="single_digit_commission"
                        />
                        <em>
                          {this.validator.message(
                            "single_digit_commission",
                            this.state.formObj.single_digit_commission,
                            "required"
                          )}
                        </em>
                      </div>
                    </div>
                    <div className="hr-line-dashed"></div> */}
                    {/* <Form.Group className="form-group" controlId="formBasicCheckbox">
                                            <Form.Label className="col-sm-3 control-label">Disable Inside Outside</Form.Label>
                                            <Form.Check type="checkbox"  label="" name="disable_inside_outside" id="disable_inside_outside" />
                                            <em>{this.validator.message('disable_inside_outside', this.state.formObj.disable_inside_outside, 'required')}</em>
                                        </Form.Group>
                                        <div className="hr-line-dashed"></div>
                                        <Form.Group className="form-group" controlId="formBasicCheckbox">
                                            <Form.Label className="col-sm-3 control-label">Disable Roulette</Form.Label>
                                            <Form.Check type="checkbox"  label="" name="disable_roulette" id="disable_roulette" />
                                            <em>{this.validator.message('disable_roulette', this.state.formObj.disable_roulette, 'required')}</em>
                                        </Form.Group>
                                        <div className="hr-line-dashed"></div>
                                        <Form.Group className="form-group" controlId="formBasicCheckbox">
                                            <Form.Label className="col-sm-3 control-label">Disable Triple Chance</Form.Label>
                                            <Form.Check type="checkbox" label="" name="disable_triple_chance" id="disable_triple_chance" />
                                            <em>{this.validator.message('disable_triple_chance', this.state.formObj.disable_triple_chance, 'required')}</em>
                                        </Form.Group>
                                        <div className="hr-line-dashed"></div>
                                        <Form.Group className="form-group" controlId="formBasicCheckbox">
                                            <Form.Label className="col-sm-3 control-label">Disable King Queen Jack</Form.Label>
                                            <Form.Check type="checkbox" label="" name="disable_king_queen_jack" id="disable_king_queen_jack" />
                                            <em>{this.validator.message('disable_king_queen_jack', this.state.formObj.disable_king_queen_jack, 'required')}</em>
                                        </Form.Group> */}

                    <div className="form-group">
                      <label className="col-sm-3 control-label">Password</label>
                      <div className="col-sm-9">
                        <input
                          type="password"
                          className="form-control"
                          name="password"
                          id="password"
                          value={this.state.formObj.password}
                          onChange={(e) => this.handleInput(e)}
                        />
                        <em>
                          {this.validator.message(
                            "password",
                            this.state.formObj.password,
                            "required"
                          )}
                        </em>
                      </div>
                    </div>
                    <div className="hr-line-dashed"></div>

                    <div className="form-group">
                      <label className="col-sm-3 control-label">
                        Confirm Password
                      </label>
                      <div className="col-sm-9">
                        <input
                          type="text"
                          className="form-control"
                          name="confirm_password"
                          id="confirm_password"
                          value={this.state.formObj.confirm_password}
                          onChange={(e) => this.handleInput(e)}
                        />
                        <em>
                          {this.validator.message(
                            "confirm_password",
                            this.state.formObj.confirm_password,
                            "required"
                          )}
                        </em>
                      </div>
                    </div>

                    <div className="hr-line-dashed"></div>

                    <div className="form-group">
                      <div className="col-sm-4 col-sm-offset-2">
                        <button
                          className="btn btn-white mr-3"
                          type="button"
                          style={{ marginRight: "1rem" }}
                          onClick={() => this.props.history.push(navigationUrl)}
                        >
                          Cancel
                        </button>
                        <button className="btn btn-primary" type="submit">
                          Save Changes
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapstatetop = (state) => ({
  ...state,
});

export default connect(mapstatetop)(AddUser);
