import React, { Component } from "react";
import InnerPageHeader from "../common/InnerPageHeader";
import { Link } from "react-router-dom";
import Pagination from "../common/Pagination";
import { listRequest } from "_actions/superstockists/superstockists.actions";

class List extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    superStockList: [],
    totalRecords: 0,
    role: 4,
  };
  componentDidMount() {
    let role = localStorage.getItem("role") || null;
    let sendObj = {
      user_guid: "",
      current_page: 1,
      items_perpage: 10,
      keyword: "",
      role: this.state.role,
    };
    this.props.dispatch(
      listRequest(sendObj, (res) => {
        if (res && res.data && res.data.total_records) {
          this.setState({
            superStockList: res.data.userdownlist,
            totalRecords: res.data.total_records,
          });
        }
      })
    );
  }

  render() {
    const { superStockList, totalRecords } = this.state;
    return (
      <>
        <InnerPageHeader
          pageTitle="SuperStockists List"
          breadcrumb="SuperStockists"
          breadcrumbPath="/super-stockists"
          action="List"
        />
        {/* <Link to={"/super-stockists/add"}>
          <button className="btn btn-primary pull-right">ADD</button>
        </Link>
        <h1>List</h1> */}
        <div className="wrapper wrapper-content animated fadeInRight">
          <div className="row">
            <div className="col-lg-12">
              <div className="ibox float-e-margins">
                <div className="ibox-title">
                  <h5 style={{ fontWeight: 700 }}>All Agents</h5>
                  <div className="ibox-tools">
                    <a
                      href="/"
                      className="btn btn-primary btn-xs"
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.history.push("/super-stockists/add");
                      }}
                    >
                      Create Super Stockists
                    </a>
                  </div>
                </div>
                <div className="ibox-content">
                  <div className="table-responsive">
                    <div
                      id="DataTables_Table_0_wrapper"
                      className="dataTables_wrapper form-inline dt-bootstrap"
                    >
                      <div className="dt-buttons btn-group">
                        <a
                          className="dt-button buttons-csv buttons-html5"
                          tabIndex="0"
                          aria-controls="DataTables_Table_0"
                          href="#"
                          style={{ marginRight: "0.5rem" }}
                        >
                          <span>CSV</span>
                        </a>
                        <a
                          className="dt-button buttons-pdf buttons-html5"
                          tabIndex="0"
                          aria-controls="DataTables_Table_0"
                          href="#"
                        >
                          <span>PDF</span>
                        </a>
                      </div>

                      <div
                        id="DataTables_Table_0_filter"
                        className="dataTables_filter"
                      >
                        <label>
                          Search:
                          <input
                            type="search"
                            className="form-control input-sm"
                            placeholder=""
                            aria-controls="DataTables_Table_0"
                          />
                        </label>
                      </div>

                      <table className="table table-striped table-bordered table-hover dataTables-example">
                        <thead>
                          <tr>
                            <th>ID</th>
                            <th>User Name</th>
                            <th>Name</th>
                            <th>Fix Limit</th>
                            <th>My Share</th>
                            <th>Max Share</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {superStockList && superStockList.length ? (
                            superStockList.map((user, index) => (
                              <tr className="gradeX" key={index}>
                                <td>{user.agent_id}</td>
                                <td>
                                  <a href="/agent/agents/4410">
                                    {user.username}
                                  </a>
                                </td>
                                <td>
                                  {user.first_name} {user.last_name}
                                </td>
                                <td className="center">{user.fixed_limit}</td>
                                <td className="center">{user.my_share}</td>
                                <td className="center">
                                  {user.my_agent_share}
                                </td>
                                <td className="">
                                  <a
                                    className="btn btn-white btn-sm"
                                    href="/agent/agents/4410/edit"
                                  >
                                    <i className="fa fa-pencil-square"></i> Edit
                                  </a>
                                  &nbsp;&nbsp; &nbsp;&nbsp;
                                  <a href="/agent/agents/4410/change_password_panel">
                                    <button
                                      type="button"
                                      className="btn btn-w-m btn-primary"
                                    >
                                      Change Password
                                    </button>
                                  </a>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr className="gradeX">
                              <td colSpan={7}>No data available in table</td>
                            </tr>
                          )}
                        </tbody>
                      </table>

                      <div className="row" style={{ margin: "15px 0px" }}>
                        <div className="col-xs-12 col-sm-6">
                          <div
                            className="dataTables_info"
                            id="DataTables_Table_0_info"
                            role="status"
                            aria-live="polite"
                          >
                            Showing 1 to 1 of 1 entries
                          </div>
                        </div>
                        <div className="col-xs-12 col-sm-6">
                          <Pagination totalRecords={totalRecords} />
                        </div>
                        <div className="clearfix visible-xs"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default List;
