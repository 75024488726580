import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { loginUser } from "../../_actions";
import SimpleReactValidator from "simple-react-validator";
import { toast, ToastContainer } from "react-toastify";
// import { Callbacks } from 'jquery';
import { Redirect } from "react-router-dom";

class LoginComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      formObj: {
        username: "",
        password: "",
      },
    };
    this.validator = new SimpleReactValidator();
  }
  setSignInModal = (state) => {
    this.setState({ showModel: state });
  };

  handleChange = (e) => {
    var { formObj } = this.state;
    formObj[e.target.name] = e.target.value;
    this.setState({ formObj });
  };

  onSubmit = () => {
    if (this.validator.allValid()) {
      let { redirect, formObj } = this.state;
      this.setState({ isLoader: true });
      this.props.dispatch(
        loginUser(formObj, (result) => {
          if (result) {
            this.setState({ redirect: true });
          } else {
            this.setState({ redirect: false });
            toast("Authentication failed!");
          }
        })
      );
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  render() {
    let { redirect } = this.state;
    if (redirect) {
      return <Redirect to="/dashboard" />;
    }
    return (
      <div className="middle-box text-center loginscreen animated fadeInDown">
        <ToastContainer />
        <div>
          <div>
            <h1 className="logo-name">T10</h1>
          </div>
          <h3>Welcome to TOTAL1010</h3>
          <p>Sign In</p>
          <form className="m-t" onSubmit={() => this.onSubmit()}>
            <div className="form-group">
              <input
                type="text"
                name="username"
                id="username"
                className="form-control"
                placeholder="Username"
                required
                onChange={(e) => this.handleChange(e)}
              />
              <em>
                {this.validator.message(
                  "username",
                  this.state.formObj.username,
                  "required"
                )}
              </em>
            </div>
            <div className="form-group">
              <input
                type="password"
                name="password"
                id="password"
                className="form-control"
                placeholder="Password"
                required
                onChange={(e) => this.handleChange(e)}
              />
              <em>
                {this.validator.message(
                  "password",
                  this.state.formObj.password,
                  "required"
                )}
              </em>
            </div>
            <button
              type="button"
              onClick={() => this.onSubmit()}
              className="btn btn-primary block full-width m-b"
            >
              Login
            </button>
          </form>
          <p className="m-t">
            {" "}
            <small>TOTAL1010 © 2022</small>{" "}
          </p>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

export default connect(mapStateToProps)(LoginComponent);
