import axios from 'axios';
import {toast} from 'react-toastify';
import config from '../lib/app-config';
import { getStorage } from "../utils";

export const userLogin = (user_name,password) => {
    return axios.post(config.api_baseurl+'login',{"username":user_name,"password":password})
    .then((response) => {
        return response.data;
    })    
    .catch(err =>{
        if(err && err.message=="Network Error"){
            toast.error("Please check your network connection.")
        }
        throw err;
    });
};

export const createNewUserRequest = () => {

}