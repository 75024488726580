import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { Link, Redirect, withRouter } from 'react-router-dom';
import { smoothlyMenu } from '../layouts/Helpers';
import $ from 'jquery';
import { logoutUser } from '../../_actions';
import { connect } from 'react-redux';
class TopHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: ""
        }
    }
    toggleNavigation(e) {
        e.preventDefault();
        $("body").toggleClass("mini-navbar");
        smoothlyMenu();
    }

    logOut = () => {
        console.log("itst foroerein")
        this.props.dispatch(logoutUser((result) => {            
            if (result) {
                this.setState({ redirect: 'login' });
            } else {
                this.setState({ redirect: 'login' });
            }
        }))
    }
    render() {
        let { redirect } = this.state;
        if (redirect == 'login') {
            return <Redirect to="/login" />            
        }
        return (
            <div className="row border-bottom">
                <nav className="navbar navbar-static-top white-bg" role="navigation" style={{marginBottom: 0}}>
                    <div className="navbar-header">
                        <a className="navbar-minimalize minimalize-styl-2 btn btn-primary " onClick={this.toggleNavigation} href="#"><i className="fa fa-bars"></i> </a>
                    </div>
                    <ul className="nav navbar-top-links navbar-right">
                        <li>
                            <a href="#" onClick={this.logOut}>
                                <i className="fa fa-sign-out"></i> Log out
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
        )
    }
}
const mapStateToProps = state => ({
    ...state
})

// export default connect(mapStateToProps,null,null,{forwardRef:true})(SignInModal);

export default connect(mapStateToProps)(withRouter(TopHeader));