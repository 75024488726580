import { performRequest } from "_apiConfig/apiHandler";
import { default as apiEndPoints } from "_apiConfig/apiEndPoints";
import config from "../lib/app-config";

export const profileDetails = (data = {}) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}users/profile`,
    data
  );
};
