import React, { Component } from "react";
import InnerPageHeader from "./InnerPageHeader";
import { Link } from "react-router-dom";
import Pagination from "./Pagination";

class LedgesViewPage extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    masterList: [],
    totalRecords: 0,
    role: 3,
    currentPage: 1,
  };
  componentDidMount() {}

  render() {
    const { masterList, totalRecords } = this.state;
    const { type, id } = this.props.match.params;
    return (
      <>
        <InnerPageHeader
          pageTitle="Master List"
          breadcrumb="Master"
          breadcrumbPath="/master"
          action="List"
        />

        <div className="row">
          <div
            id="reportrange"
            className="my_ledgers_dated_picker col-lg-3 col-md-4 col-sm-6 col-xs-11"
          >
            <input
              className="form-control"
              type="text"
              name="daterange"
              value="01/01/2015 - 01/31/2015"
            />
            {/* <i className="glyphicon glyphicon-calendar fa fa-calendar"></i>
            &nbsp;
            <span> - </span>
            <b className="caret"></b> */}
          </div>
          <div
            className="col-lg-3 col-md-4 col-sm-6 col-xs-12"
            style={{ margin: "11px 0px" }}
          >
            <a className="btn btn-primary btn-xs search_date_btn">Search</a>
          </div>
        </div>

        <div className="wrapper wrapper-content animated fadeInRight">
          <div className="row">
            <div className="col-lg-12">
              <div className="ibox float-e-margins">
                <div className="ibox-title">
                  <h5>Agent Ledger</h5>
                  <div className="ibox-tools">
                    <a
                      className="dropdown-toggle"
                      data-toggle="dropdown"
                      href="#"
                      aria-expanded="true"
                    >
                      <i className="fa fa-wrench"></i>
                    </a>
                    <ul className="dropdown-menu dropdown-user">
                      <li>
                        <a
                          target="_blank"
                          href="/agent/agents/3116/ledger_show_pdf.pdf"
                        >
                          Export PDF
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="ibox-content">
                  <div className="table-responsive">
                    <table class="table">
                      <thead>
                        <tr>
                          <th>DATE/TIME</th>
                          <th>Collection Name</th>
                          <th>DEBIT</th>
                          <th>CREDIT</th>
                          <th>Balance</th>
                          <th>Note</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td id="getdate_2794093">
                            <i class="fa fa-clock-o"></i>&nbsp;May 29, 12:16:10
                            AM
                          </td>

                          <td>TeenPatti-T-20 - 28 May</td>
                          <td> - </td>
                          <td>16642.27</td>

                          <td>85931.16</td>
                          <td>
                            <div class="note-short-text full_text_modal"></div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default LedgesViewPage;
