import React, { Component } from "react";
import InnerPageHeader from "./InnerPageHeader";
import { Link } from "react-router-dom";
import Pagination from "./Pagination";

class UserViewPage extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    masterList: [],
    totalRecords: 0,
    role: 3,
    currentPage: 1,
  };
  componentDidMount() {}

  render() {
    const { masterList, totalRecords } = this.state;
    const { type, id } = this.props.match.params;
    return (
      <>
        <InnerPageHeader
          pageTitle="Master List"
          breadcrumb="Master"
          breadcrumbPath="/master"
          action="UserName"
        />

        <div className="wrapper wrapper-content animated fadeInRight">
          <div className="row">
            <div className="col-lg-12">
              <div className="ibox float-e-margins">
                <div className="ibox-title">
                  <h1>Agent Dashboard</h1>
                </div>
                <div className="ibox-content">
                  <div className="table-responsive">
                    <p style={{ textAlign: "center" }}>
                      <a
                        href="/agent/agents/3116/receive_cash_from_agent_panel"
                        style={{ marginRight: "0.25rem" }}
                      >
                        <button
                          type="button"
                          className="btn btn-primary btn-lg"
                        >
                          Recieve Cash
                        </button>
                      </a>
                      <a
                        href="/agent/agents/3116/pay_cash_to_agent_panel"
                        style={{ marginRight: "0.25rem" }}
                      >
                        <button
                          type="button"
                          className="btn btn-primary btn-lg"
                        >
                          Pay Cash
                        </button>
                      </a>
                      <a
                        href="/agent/agents/3116/ledger_show"
                        style={{ marginRight: "0.25rem" }}
                        onClick={(e) => {
                          e.preventDefault();
                          this.props.history.push(`/ledger/ledger/${id}`);
                        }}
                      >
                        <button
                          type="button"
                          className="btn btn-primary btn-lg"
                        >
                          Ledger
                        </button>
                      </a>
                      <a
                        href="/agent/agents/3116/cash_ledger"
                        style={{ marginRight: "0.25rem" }}
                      >
                        <button
                          type="button"
                          className="btn btn-primary btn-lg"
                        >
                          Cash Ledger
                        </button>
                      </a>
                      <a
                        href="/agent/agents/3116/match_ledger"
                        style={{ marginRight: "0.25rem" }}
                      >
                        <button
                          type="button"
                          className="btn btn-primary btn-lg"
                        >
                          Match Ledger
                        </button>
                      </a>
                      <a
                        href="/agent/agents/3116/direct_agents"
                        style={{ marginRight: "0.25rem" }}
                      >
                        <button
                          type="button"
                          className="btn btn-primary btn-lg"
                        >
                          Direct Agents
                        </button>
                      </a>
                      <a
                        href="/agent/agents/3116/direct_clients"
                        style={{ marginRight: "0.25rem" }}
                      >
                        <button
                          type="button"
                          className="btn btn-primary btn-lg"
                        >
                          Direct Clients
                        </button>
                      </a>
                      <a
                        href="/agent/agents/3116/changelog"
                        style={{ marginRight: "0.25rem" }}
                      >
                        <button
                          type="button"
                          className="btn btn-primary btn-lg"
                        >
                          Coin History
                        </button>
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-4">
            <div className="ibox float-e-margins">
              <div className="ibox-title">
                <h5>Coins</h5>
              </div>
              <div className="ibox-content">
                <h1 className="no-margins">-273202.66</h1>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="ibox float-e-margins">
              <div className="ibox-title">
                <h5>Ledger Balance</h5>
              </div>
              <div className="ibox-content">
                <h1 className="no-margins">-52429.01</h1>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default UserViewPage;
