import axios from "axios";
import { toast } from "react-toastify";
import config from "../lib/app-config";
import { getStorage } from "../utils";
import { performRequest } from "_apiConfig/apiHandler";
import { default as apiEndPoints } from "_apiConfig/apiEndPoints";

// export const create = (data) => {
//   return axios
//     .post(config.api_baseurl + "users/create", data)
//     .then((response) => {
//       return response.data;
//     })
//     .catch((err) => {
//       if (err && err.message == "Network Error") {
//         toast.error("Please check your network connection.");
//       }
//       throw err;
//     });
// };

export const create = (data) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}users/create`,
    data
  );
};

// export const list = (data) => {
//   const token = localStorage.getItem("token") || null;
//   return axios
//     .post(
//         `${config.api_baseurl}users/downline`,
//         data,
//     //   `${config.api_baseurl}users/downline?user_guid=${data.user_guid}&current_page=${data.current_page}&items_perpage=${data.items_perpage}&keyword=${data.keyword}&role=${data.role}`,
//       {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       },
//     )
//     .then((response) => {
//       return response.data;
//     })
//     .catch((err) => {
//       if (err && err.message == "Network Error") {
//         toast.error("Please check your network connection.");
//       }
//       throw err;
//     });
// };

export const list = (data) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}users/downline`,
    data
  );
};
