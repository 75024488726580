import React, { Fragment, Component } from "react";
import { location, withRouter } from "react-router-dom";
import Main from "../../components/layouts/Main";
import UserViewPage from "components/common/UserViewPage";
import withAuth from "../../_helpers/withAuth";
import { connect } from "react-redux";

class UserView extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Main>
        <UserViewPage {...this.props} />
      </Main>
    );
  }
}
const mapstatetop = (state) => ({
  ...state,
});

export default connect(mapstatetop)(UserView);
