import React, { Component } from "react";
import { connect } from "react-redux";
import SimpleReactValidator from "simple-react-validator";
import { toast, ToastContainer } from "react-toastify";
import { Redirect } from "react-router-dom";
import AddUser from "components/common/AddUser";
import { createRequest } from "_actions/stockists/stockists.actions";
class Add extends Component {
  constructor(props) {
    super(props);
    this.state = {
      role: 5,
    };

    this.validator = new SimpleReactValidator();
  }

  addStockist = (sendObj) => {
    sendObj.role = this.state.role;
    this.props.dispatch(
      createRequest(sendObj, (res) => {
        this.props.history.push("/stockists");
      })
    );
  };
  render() {
    let { redirect } = this.state;
    if (redirect) {
      return <Redirect to={redirect} />;
    }
    return (
      <>
        <AddUser
          heading="New Stockists"
          breadcrumbTitle="New Stockists"
          breadcrumb="Agents"
          navigationUrl="/stockists"
          onSubmitFunc={this.addStockist}
          role={this.state.role}
          action="Create Stockists"
          history={this.props.history}
          parentType="stockist"
        />
      </>
    );
  }
}
const mapstatetop = (state) => ({
  ...state,
});

export default connect(mapstatetop)(Add);
