import React, { Component } from "react";
import LoginComponent from '../../components/Login';
import { getStorage } from '../../utils';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
class Login extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        var {user_id} = this.props.auth.userLogin;
        if(user_id){
            return <Redirect to="/dashboard" />
        }
        console.log(this.props,"propspropsprops")
        return (
            <>
                <LoginComponent />
            </>
        )
    }
}
const maptostateprops = state =>({
...state
})
export default connect(maptostateprops)(Login);