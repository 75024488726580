import React, { useState, useEffect } from "react";

const Pagination = (props) => {
  const { totalRecords } = props;
  const [numberOfPages, setNumberOfPages] = useState(0);

  useEffect(() => {
    setNumberOfPages(Math.ceil(totalRecords / 10));
  }, [totalRecords]);

  return (
    <div
      className="dataTables_paginate paging_simple_numbers"
      id="DataTables_Table_0_paginate"
    >
      <ul className="pagination">
        <li
          className="paginate_button previous disabled"
          id="DataTables_Table_0_previous"
        >
          <a
            href="#"
            aria-controls="DataTables_Table_0"
            data-dt-idx="0"
            tabIndex="0"
          >
            Previous
          </a>
        </li>
        {numberOfPages
          ? [...Array(numberOfPages)].map((val, index) => (
              <li className="paginate_button active" key={index}>
                <a
                  href="#"
                  aria-controls="DataTables_Table_0"
                  data-dt-idx="1"
                  tabIndex={`${index + 1}`}
                >
                  {index + 1}
                </a>
              </li>
            ))
          : null}
        <li className="paginate_button next" id="DataTables_Table_0_next">
          <a
            href="#"
            aria-controls="DataTables_Table_0"
            data-dt-idx="4"
            tabIndex="0"
          >
            Next
          </a>
        </li>
      </ul>
    </div>
  );
};
export default Pagination;
