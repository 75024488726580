import React, { Component } from "react";
import { Link } from "react-router-dom";
import InnerPageHeader from '../common/InnerPageHeader';
import { connect } from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';
import { toast, ToastContainer } from 'react-toastify';
import { Redirect } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import { createRequest } from "../../_actions/client/client.actions";
class Add extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formObj: {
                username:"",
                first_name:"",
                last_name:"",
                fixed_limit:"",
                my_share:"",          
                match_commission:"",
                session_commission:"",
                password:"",
                confirm_password:"",
                role:7
            }
        }

        this.validator = new SimpleReactValidator();
    }

    onSubmit = (e) => {
        e.preventDefault();
        let { formObj } = this.state;
        if (this.validator.allValid()) {
            this.props.dispatch(createRequest(formObj,(res)=>{
                if(res)
                    this.setState({redirect:"master"});                
            }))
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }
    render() {
        let {redirect} = this.state;
        if(redirect){
            return <Redirect to={redirect}/>
        }
        return (
            <>
                <InnerPageHeader pageTitle="Add Client" breadcrumb="Client" breadcrumbPath="/my-client" action="Add" />
                <div className="wrapper wrapper-content animated fadeInRight">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="ibox float-e-margins">
                                <div className="ibox-title">
                                    <h5>Create New Client</h5>
                                </div>
                                <div className="ibox-content">
                                    <form onSubmit={this.onSubmit} className="form-horizontal">
                                        <div className="form-group">
                                            <label className="col-sm-3 control-label">User name</label>
                                            <div className="col-sm-9">
                                                <input type="text" className="form-control" name="username" id="username" />
                                                <em>{this.validator.message('username', this.state.formObj.username, 'required')}</em>
                                            </div>
                                        </div>
                                        <div className="hr-line-dashed"></div>
                                        <div className="form-group">
                                            <label className="col-sm-3 control-label">First name</label>
                                            <div className="col-sm-9">
                                                <input type="text" className="form-control" name="first_name" id="first_name" />
                                                <em>{this.validator.message('first_name', this.state.formObj.first_name, 'required')}</em>
                                            </div>
                                        </div>
                                        <div className="hr-line-dashed"></div>
                                        <div className="form-group">
                                            <label className="col-sm-3 control-label">Last name</label>
                                            <div className="col-sm-9">
                                                <input type="text" className="form-control" name="last_name" id="last_name" />
                                                <em>{this.validator.message('last_name', this.state.formObj.last_name, 'required')}</em>
                                            </div>
                                        </div>                                        
                                        <div className="hr-line-dashed"></div>
                                        <div className="form-group">
                                            <label className="col-sm-3 control-label">Fix Limit</label>
                                            <div className="col-sm-9">
                                                <input type="text" className="form-control" name="fix_limit" id="fix_limit" />
                                                <em>{this.validator.message('fix_limit', this.state.formObj.fix_limit, 'required')}</em>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="col-sm-3 control-label">My Share</label>
                                            <div className="col-sm-9">
                                                <input type="text" className="form-control" name="my_share" id="my_share" />
                                                <em>{this.validator.message('my_share', this.state.formObj.my_share, 'required')}</em>
                                            </div>
                                        </div>                                        
                                        <div className="hr-line-dashed"></div>
                                        <div className="form-group">
                                            <label className="col-sm-3 control-label">Match Commission</label>
                                            <div className="col-sm-9">
                                                <input type="text" className="form-control" name="match_commission" id="match_commission" />
                                                <em>{this.validator.message('match_commission', this.state.formObj.match_commission, 'required')}</em>
                                            </div>
                                        </div>
                                        <div className="hr-line-dashed"></div>
                                        <div className="form-group">
                                            <label className="col-sm-3 control-label">Session Commission</label>
                                            <div className="col-sm-9">
                                                <input type="text" className="form-control" name="session_commission" id="session_commission" />
                                                <em>{this.validator.message('session_commission', this.state.formObj.session_commission, 'required')}</em>
                                            </div>
                                        </div>
                                        <div className="hr-line-dashed"></div>
                                        <div className="form-group"><label className="col-sm-3 control-label">Password</label>
                                            <div className="col-sm-9">
                                                <input type="password" className="form-control" name="password" id="password" />
                                                <em>{this.validator.message('password', this.state.formObj.password, 'required')}</em>
                                            </div>
                                        </div>
                                        <div className="form-group"><label className="col-sm-3 control-label">Confirm Password</label>
                                            <div className="col-sm-9">
                                                <input type="text" className="form-control" name="confirm_password" id="confirm_password" />
                                                <em>{this.validator.message('confirm_password', this.state.formObj.confirm_password , 'required')}</em>
                                            </div>
                                        </div>

                                        <div className="hr-line-dashed"></div>
                                        
                                        <div className="form-group">
                                            <div className="col-sm-4 col-sm-offset-2">
                                                <button className="btn btn-white" type="submit">Cancel</button>
                                                <button className="btn btn-primary" type="submit">Save Changes</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
const mapstatetop = state => ({
    ...state
})

export default (connect(mapstatetop)(Add));