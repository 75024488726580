import React, { Component } from "react";
import { Switch, Route, Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import Login from "./pages/Auth";
import Dashboard from "./pages/Dashboard";
import PrivateRoute from "./components/authHoc/PrivateRoute";
import AddMaster from "./pages/Master/Add";
import Master from "./pages/Master/List";
import Stockists from "./pages/Stockists/List";
import SubCompany from "./pages/SubCompany/List";
import SuperStockists from "./pages/SuperStockists/List";
import Agent from "./pages/Agent/List";

import StockistsAdd from "./pages/Stockists/Add";
import SubCompanyAdd from "./pages/SubCompany/Add";
import SuperStockistsAdd from "./pages/SuperStockists/Add";
import AgentAdd from "./pages/Agent/Add";

import Client from "./pages/Client/List";
import AddClient from "./pages/Client/Add";
import UserView from "pages/UserView/UserView";
import LedgesView from "pages/LedgesView/LedgesView";

class App extends Component {
  render() {
    return (
      <Switch>
        <Route exact path="/" component={Login} />
        <Route exact path="/login" component={Login} />
        {/* <PrivateRoute exact path={paths.fnb.dashboard.dietitian_dashboard.list} component={FnbDietitian} /> */}
        <PrivateRoute exact path="/dashboard" component={Dashboard} />
        <PrivateRoute exact path="/master/add" component={AddMaster} />
        <PrivateRoute exact path="/master" component={Master} />
        <PrivateRoute
          exact
          path="/super-stockists"
          component={SuperStockists}
        />
        <PrivateRoute
          exact
          path="/super-stockists/add"
          component={SuperStockistsAdd}
        />
        <PrivateRoute
          exact
          path="/super-stockists/stockists"
          component={Stockists}
        />
        <PrivateRoute exact path="/stockists" component={Stockists} />
        <PrivateRoute exact path="/stockists/add" component={StockistsAdd} />
        <PrivateRoute exact path="/sub-company" component={SubCompany} />
        <PrivateRoute exact path="/sub-company/add" component={SubCompanyAdd} />
        <PrivateRoute exact path="/agent" component={Agent} />
        <PrivateRoute exact path="/agent/add" component={AgentAdd} />
        <PrivateRoute exact path="/manage-subcompany" component={SubCompany} />
        <PrivateRoute
          exact
          path="/manage-subcompany/add"
          component={SubCompanyAdd}
        />

        <PrivateRoute exact path="/my-clients" component={Client} />
        <PrivateRoute exact path="/my-clients/add" component={AddClient} />
        <PrivateRoute exact path="/userview/:type/:id" component={UserView} />
        <PrivateRoute exact path="/ledger/:type/:id" component={LedgesView} />
      </Switch>
    );
  }
}

export default App;
