import React, { Fragment,Component } from "react";
import { location,withRouter } from 'react-router-dom';
import Main from '../../components/layouts/Main';
import AddClient from '../../components/Client/Add';
import { connect } from 'react-redux';
class Add extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render()  {
        return (
            <Main>
                <AddClient/>
            </Main>
        )
    }
}
const mapstatetop = state => ({
    ...state
})

export default (connect(mapstatetop)(Add));